<template>
  <div class="d-flex flex-row">
    <!--begin::Content-->
    <div class="flex-row-fluid ml-lg-8">
      <b-tabs
        class="hide-tabs"
        v-model="tabIndex"
        :no-nav-style="true"
      >
        <b-tab active>
          <ScheduleInformation :schedule="schedule"></ScheduleInformation>
        </b-tab>

      </b-tabs>
    </div>
    <!--end::Content-->
  </div>
</template>

<script>
import { mapState } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

import ScheduleInformation from "@/view/pages/ScheduleManager/view-comp/ScheduleInformation";



export default {
  name: "ViewSchedule",

  components: {
    ScheduleInformation,
  },

  data() {
    return {
      tabIndex: 0,
    };
  },
  computed: {
      queue(){
          return this.queues.find(queue => queue.id == this.$route.params.id) || {}
      },
      ...mapState({
          queues: state => state.queues.queues,
      }),

      schedule(){
          return this.dschedule.find(schedule=>schedule.id == this.$route.params.id)
      },
      ...mapState({
          dschedule: state => state.dschedule.dschedule,
      }),

      
  },  
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "ScheduleManager", route: "/admin/schedule-manager" },
      { title: "ViewSchedule" },
    ]);
    
   
  },
  methods: {
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      let target = event.target;
      if (!event.target.classList.contains("navi-link")) {
        target = event.target.closest(".navi-link");
      }

      const tab = target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".navi-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(target.getAttribute("data-tab"));

      // set current active tab
      target.classList.add("active");
    },
  },
};
</script>
