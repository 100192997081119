<template>
  <!--begin::Card-->
  <div class="card card-custom" :dir="$t('clan')=='ar'?'rtl':'ltr'">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">
          {{$t('ViewSchedule')}}
        </h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1"
          >{{$t('ChangeScheduleInformation')}}</span
        >
      </div>
      <div class="card-toolbar">
        <button
          type="reset"
          class="btn btn-success mr-2"
          @click="save()"
          ref="kt_save_changes"
        >
          {{$t('Save Changes')}}
        </button>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form">
      <div class="card-body">
        <!--begin::Heading-->
        <!--begin::Form Group-->
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label">{{$t('name')}}</label>
          <div class="col-lg-9 col-xl-6">
            <input
              class="form-control form-control-lg form-control-solid"
              type="text"
              v-model="schedule.name"
            />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label">{{$t('WorkingDay')}}</label>
          <div class="col-lg-9 col-xl-6">
            <div>
              <vue-select
                name="clients"
                label="name"
                :key="'clients-field'"
                :options="workingDays"
                :closeOnSelect="false"
                :value="days"
                multiple
                @input="updateWorkingDays"
              >
              </vue-select>
            </div>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-xl-3 col-lg-2 col-form-label"
            >{{$t('WorkingHours')}}</label
          >
          <div class="col-lg-9 col-xl-2 ">
            <datetime  v-model="schedule.working_hours_from"   type='time' title='Select the time' :use12-hour="true" class="form-control form-control-solid form-control-lg form__input">

                          </datetime>
          </div>
            <span class="col-lg-2 text-center">{{$t('To')}}</span>
          <div class="col-lg-9 col-xl-2 " >
            <datetime v-model="schedule.working_hours_to"    type='time' title='Select the time' :use12-hour="true" class="form-control form-control-solid form-control-lg form__input">

                          </datetime>
          </div>
          
        </div>

        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label">{{$t('workinghourcharges')}} ( {{WorkinghourTypeToggledValue}} )</label>
          <div class="col-lg-9 col-xl-6">
            <span class="switch switch-icon switch-sm mt-2 mb-2" style="float:right" >
                <label>
                  <input
                    type="checkbox"
                    name="select"
                    v-model="WorkinghourTypeToggled"
                    @click="setWorkingToggle"
                    
                  />
                  <span></span>
                </label>
              </span>
              <div class="">
            <input
              class="form-control form-control-lg form-control-solid"
              type="text"
             v-model="schedule.working_hour_charges_value"
            />
          </div>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label">{{$t('Nonworkinghourcharges')}} ( {{NoNWorkinghourTypeToggledValue}} )</label>
          <div class="col-lg-9 col-xl-6">
            <span class="switch switch-icon switch-sm mt-2 mb-2" style="float:right" >
                <label>
                  <input
                    type="checkbox"
                    name="select"
                    v-model="NoNWorkinghourTypeToggled"
                    @click="setNoNWorkingToggle"
                    
                  />
                  <span></span>
                </label>
              </span>
              <div class="">
            <input
              class="form-control form-control-lg form-control-solid"
              type="text"
             v-model="schedule.non_working_hour_charges_value"
            />
          </div>
          </div>
        </div>


        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label">{{$t('Drivers')}}</label>
          <div class="col-lg-9 col-xl-6">
            <div>
              <vue-select
                name="drivers"
                label="full_name"
                :key="'clients-field'"
                :options="createData.drivers"
                :value="drivers"
                :closeOnSelect="false"
                multiple
                @input="updateDrivers"
              >
              </vue-select>
            </div>
          </div>
        </div>
       
       
      </div>
    </form>
    <!--end::Form-->
  </div>
  <!--end::Card-->
</template>

<script>
import Swal from "sweetalert2";

export default {
  name: "ScheduleInformation",

  props: {
    schedule:{
      type:Object,
      required:true
    }
  },
  data() {
    return {
      createData: [],
      drivers:[],
      days:[],
      workingDays: ['Sudnay','Monday','Tuesday','Wedensday','Thuersday','Friday','Saturday'],
      toggled:false,
      WorkinghourTypeToggled:false,
      WorkinghourTypeToggledValue:'Fixed',
      NoNWorkinghourTypeToggled:false,
      NoNWorkinghourTypeToggledValue:'Fixed',
    };
  },
  beforeMount(){
    this.drivers = this.schedule.drivers
    //console.log(this.drivers)
    if(this.schedule.non_working_hour_charges_mode ==='Fixed'){
      this.NoNWorkinghourTypeToggled=false,
      this.NoNWorkinghourTypeToggledValue='Fixed'
    }
    else{
      this.NoNWorkinghourTypeToggled=true,
      this.NoNWorkinghourTypeToggledValue='Percentage'
    }

    if(this.schedule.working_hour_charges_mode ==='Fixed'){
      this.WorkinghourTypeToggled = false;
        this.WorkinghourTypeToggledValue='Fixed'
    }
    else{
      this.WorkinghourTypeToggled = true
        this.WorkinghourTypeToggledValue='Percentage'
       
    }

    this.days = JSON.parse(this.schedule.working_days)
  },
  async mounted() {
    this.createData = await this.$store.dispatch("queues/fetchCreateData");
    
    //console.log(this.schedule.working_days)
    
    //var dateFrom = new Date(this.schedule.working_hours_from)
    //this.schedule.working_hours_from = this.schedule.working_hours_from.toISOString()
    //console.log(dateFrom)
    //console.log(this.schedule)
    
  },
  methods: {
      setWorkingToggle(){
          if(this.WorkinghourTypeToggled === true){
        this.WorkinghourTypeToggled = false;
        this.WorkinghourTypeToggledValue='Fixed'
        this.schedule.working_hour_charges_mode='Fixed'
        
        

      }
      else if(this.WorkinghourTypeToggled === false){
        this.WorkinghourTypeToggled = true
        this.WorkinghourTypeToggledValue='Percentage'
        this.schedule.working_hour_charges_mode='Percentage'
       
        
      }
      
      },
      setNoNWorkingToggle(){
          if(this.NoNWorkinghourTypeToggled === true){
        this.NoNWorkinghourTypeToggled = false;
        this.NoNWorkinghourTypeToggledValue='Fixed'
        this.schedule.non_working_hour_charges_mode = 'Fixed'
        
        

      }
      else if(this.NoNWorkinghourTypeToggled === false){
        this.NoNWorkinghourTypeToggled = true
        this.NoNWorkinghourTypeToggledValue='Percentage'
        this.schedule.non_working_hour_charges_mode = 'Percentage'
       
        
      }
      
      },
    updateWorkingDays(value) {
      this.days = value;
      this.schedule.working_days = JSON.stringify(this.days)
    },
    updateDrivers(value) {
      this.drivers = value;
    },
    save() {
      // set spinner to submit button
      //this.schedule.drivers = '1,3'
      const submitButton = this.$refs["kt_save_changes"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // dummy delay
       var string = ''
      if(this.drivers.length ===1){
        //console.log('-------')
        string  = this.drivers[0].id
       // console.log('--------------')
      }
      else{

    
      for(var s=0; s<this.drivers.length; s++){
       if(s==0){
        string = this.drivers[s].id
       }else{
         string = string + ',' + this.drivers[s].id
       }
       
      }
        }
        
      this.schedule.drivers = string.toString()

      setTimeout(() => {
        // send update request
        this.$store.dispatch("dschedule/update", this.schedule);

        Swal.fire({
          title: "",
          text:this.$t('QeueAlert'),
          icon: "success",
          confirmButtonClass: "btn btn-secondary",
        });

        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      }, 2000);
    },
    cancel() {},
  },
};
</script>
